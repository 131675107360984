import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { Form, Spinner, Tab, Tabs } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ReactDatePicker from "react-datepicker";
import { BiSolidGridAlt } from "react-icons/bi";
import { BsList } from "react-icons/bs";
import { RxDragHandleDots2 } from "react-icons/rx";
import { FaAnglesLeft } from "react-icons/fa6";
import { FaAnglesRight } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import addOnImg from "../../img/addOn.png";
import connectCalendar from "../../img/connectcalendar.svg";
import deleteIcon from "../../img/deleteIcon.svg";
import docFolderIcon from "../../img/docFolderIcon.svg";
import downloadIcon from "../../img/downloadIcon.svg";
import forwardIcon from "../../img/forwardIcon.svg";
import pdfFolderIcon from "../../img/pdfFolderIcon.svg";
import starImg from "../../img/star.svg";
import excelFolderIcon from "../../img/xlsFolderIcon.svg";
import {
  useCreateMeetingByDealIDMutation,
  useDeleteMeetingMutation,
  useGetDealMeetingQuery,
  useUpdateMeetingMutation,
} from "../../redux-setup/apiSlice";
import {
  StrictModeDroppable,
  formatDate,
  getRandomColor,
  removeQuotationMarks,
} from "../../utils/CommonFuntions";
import { MeetingModalBox } from "../FrameworkHomepage/Meetingaccordian";
import { Details, MyDeletePopup } from "../FrameworkHomepage/Meetingcomponent";
import { DynamicTooltipbox } from "../Resetpasswordpage/Tooltipbox";
import Card from "../common/Card";
import { usePagination } from "../../utils/hooks";
const pendingTimelineIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={46}
    height={46}
    viewBox="0 0 46 46"
    fill="none"
  >
    <g filter="url(#filter0_d_275_1864)">
      <circle cx={23} cy={23} r={10} fill="white" />
      <circle cx={23} cy={23} r={8} stroke="#017CFF" strokeWidth={4} />
    </g>
    <defs>
      <filter
        id="filter0_d_275_1864"
        x={0}
        y={0}
        width={46}
        height={46}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.486275 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_275_1864"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_275_1864"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
const notSheduledIcon = (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      id="Ellipse 5"
      cx={10}
      cy={10}
      r={8}
      fill="white"
      stroke="#94A3B8"
      strokeWidth={4}
    />
  </svg>
);
const ProfileImage = ({ firstName = "", lastName = "" }) => {
  const getStoredColor = () => {
    const storedColor = localStorage.getItem(`color-${firstName}-${lastName}`);
    return storedColor || getRandomColor();
  };
  const backgroundColor = getStoredColor();
  useEffect(() => {
    // Store the color in local storage when it changes
    localStorage.setItem(`color-${firstName}-${lastName}`, backgroundColor);
  }, [backgroundColor, firstName, lastName]);

  return (
    <div className="d-flex align-items-center me-4 mb-3">
      <div
        style={{
          width: "34px",
          height: "34px",
          borderRadius: "50%",
          display: "flex",
          fontFamily: "Figtree",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: "300",
          marginRight: "10px",
          backgroundColor: backgroundColor,
          color: "#000000",
        }}
      >
        {`${firstName?.charAt(0)?.toUpperCase()}${lastName
          ?.charAt(0)
          ?.toUpperCase()}`}
      </div>

      <div>
        <h5 className="m-0 profile-name" style={{ fontSize: "12px" }}>
          {firstName} {lastName}
        </h5>
      </div>
    </div>
  );
};
const TimelineTab = ({ data, deal = {}, dealLoading }) => {
  const [meetingModal, setMeetingModal] = useState(false);
  const [updateMeeting, { isLoading: updating }] = useUpdateMeetingMutation();
  const user = useSelector((state) => state.dataSlice.user);
  const [meetings, setMeetings] = useState([]);
  const [showExtSummaryForID, setShowExtSummaryForID] = useState(-1);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [editSummary, setEditSummary] = useState(null);
  const [deleteMeeting, { isLoading }] = useDeleteMeetingMutation();
  const [openCollapses, setOpenCollapses] = useState([]);
  const {
    data: meetingsData,
    isLoading: initialmeetingLoading,
    isFetching: fetchingMeetingData,
  } = useGetDealMeetingQuery(deal?.id, {
    skip: !deal?.id,
  });
  useEffect(() => {
    Array.isArray(meetingsData?.meeting) && setMeetings(meetingsData.meeting);
    if (meetings?.length) {
      let temp = meetings.map((item, i) => i);
      setOpenCollapses([...temp]);
    }
  }, [meetings, meetingsData]);
  const summaryRef = useRef("");
  const saveData = () => {
    updateMeetingData({
      id: editSummary,
      date: { summary: summaryRef.current.value },
    });
  };
  const updateMeetingDate = () => {
    updateMeetingData({
      id: detailsModal.id,
      data: { meetingStartDateTime: detailsModal.meetingStartDateTime },
    });
  };
  const updateMeetingData = (data) => {
    updateMeeting(data)
      .unwrap()
      .then(() => {
        toast.success(
          `${editSummary ? "Summary" : "Date"} Updated Successfully`
        );
        editSummary && setEditSummary(null);
        detailsModal && setDetailsModal(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Update failed");
      });
    // setEditSummary(null)
  };
  let meetingLoading =
    initialmeetingLoading || fetchingMeetingData || dealLoading;

  const handleToggle = (collapseKey) => {
    let copy = [...openCollapses];
    if (openCollapses.includes(collapseKey)) {
      setOpenCollapses(openCollapses.filter((key) => key !== collapseKey));
    } else setOpenCollapses([...copy, collapseKey]);
  };
  const confirm = () => {
    toast.promise(
      deleteMeeting(modalDeleteShow)
        .unwrap()
        .then(() => {
          // refetch();
          setModalDeleteShow(false);
        }),
      {
        pending: "Deleting",
        success: "Deleted Successfully👌",
        error: "Error while Delete 🤯",
      }
    );
  };
  const [activeTab, setActiveTab] = useState("Setup");

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  // Helper

  // share
  const handleShare = async (fileName) => {
    const fileUrl = `path/to/your/files/${fileName}`;
    if (navigator.share) {
      try {
        await navigator.share({
          title: fileName,
          url: fileUrl,
        });
      } catch (error) {
        console.error("Error sharing the file:", error);
      }
    } else {
      toast.error("Web Share API is not supported in your browser.");
    }
  };
  let attachments = Array.isArray(meetings)
    ? meetings.filter((it) => !it.meetingStartLink)
    : [];
  const handleUpcoming = (item) => {
    return new Date(item.meetingStartDateTime).getTime() > new Date().getTime();
  };
  const [createMeeting, { isLoading: creating }] =
    useCreateMeetingByDealIDMutation();
  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);

    const formatDateTime = (dateTime) => dateTime.toISOString();

    for (const file of files) {
      let formData = new FormData();
      formData.append("accountId", parseInt(data?.id, 10));
      formData.append("userId", parseInt(data.userId, 10));
      formData.append("dealId", parseInt(deal.id, 10));
      formData.append("attachment", file);

      const formattedStartDateTime = formatDateTime(new Date());
      formData.append("meetingStartDateTime", formattedStartDateTime);

      try {
        await createMeeting(formData).unwrap();
        toast.success(`Transcript (${file.name}) Uploaded successfully`);
      } catch (err) {
        console.error(err);
        toast.error(`Transcript (${file.name}) Upload failed`);
      }
    }
  };

  function handleDragEnd(result) {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    } else if (source?.index === destination?.index) {
      return;
    } else {
      const updatedTemplate = JSON.parse(JSON.stringify(meetings));
      try {
        const dragItemData = updatedTemplate.splice(source.index, 1)[0];
        updatedTemplate.splice(destination.index, 0, dragItemData);
      } catch (error) {
        console.error(error);
      }

      setMeetings(updatedTemplate);
    }
  }

  const handleExtensiveSummary = (summary) => {
    try {
      if (Array.isArray(summary) && summary.length > 0) {
        return (
          <div className="extensive-summary">
            {summary.map((topic, id) => {
              return (
                <React.Fragment key={id}>
                  <h4>{topic.title}</h4>
                  <p>{topic.detail}</p>
                </React.Fragment>
              );
            })}
          </div>
        );
      }
      let parsedSummary = JSON.parse(summary);
      if (parsedSummary?.topics && Array.isArray(parsedSummary.topics)) {
        return (
          <div className="extensive-summary">
            {parsedSummary.topics.map((topic, id) => {
              return (
                <React.Fragment key={id}>
                  <h4>{topic.title}</h4>
                  <p>{topic.detail}</p>
                </React.Fragment>
              );
            })}
          </div>
        );
      } else {
        return (
          <div className="extensive-summary">
            {Object.entries(parsedSummary.topics).map(([key, value]) => {
              return (
                <React.Fragment key={key}>
                  <h4>{key.toString()}</h4>
                  <p>{value.toString()}</p>
                </React.Fragment>
              );
            })}
          </div>
        );
      }
    } catch (error) {
      // This could be removed in the future
      return (
        <>
          {summary && (
            <div
              className="extensive-summary mt-4"
              dangerouslySetInnerHTML={{
                __html: summary?.replace(/\n/g, "<br><br>"),
              }}
            />
          )}
        </>
      );
    }
  };

  // Sorting Setup
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  useEffect(() => {
    sortMeetings();
  }, [sortBy, sortOrder]);

  const sortMeetings = () => {
    const sortedMeetings = [...meetings].sort((a, b) => {
      if (!sortBy) {
        return 1;
      } else if (sortBy === "title") {
        if (!sortOrder) {
          return 1;
        } else if (sortOrder === "ascending") {
          return a.title.localeCompare(b.title);
        } else {
          return b.title.localeCompare(a.title);
        }
      } else {
        if (!sortOrder) {
          return 1;
        } else if (sortOrder === "ascending") {
          return (
            new Date(a.meetingStartDateTime) - new Date(b.meetingStartDateTime)
          );
        } else {
          return (
            new Date(b.meetingStartDateTime) - new Date(a.meetingStartDateTime)
          );
        }
      }
    });

    return sortedMeetings;
  };

  // Pagination setup
  const {
    currentPage,
    totalPages,
    pages,
    goToNextPage,
    goToPreviousPage,
    goToFirstPage,
    goToLastPage,
    setPage,
  } = usePagination(meetings?.length || 0);

  const sortedMeetings = sortMeetings();
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const currentMeetings = sortedMeetings.slice(startIndex, endIndex);

  return (
    <div className="businessCaseBuilder">
      {/* <div>
        {meetingLoading || noDealsMeetingLoading ? (
          <Spinner
            style={{
              position: "absolute",
              top: "26px",
              right: "40px",
            }}
          />
        ) : activeTab !== "attachments" ? ( // Conditionally render buttons
          <button className="generateBtn connectCalendarBtn">
            <DynamicTooltipbox
              infoText={"Coming soon"}
              label={
                <>
                  Connect your Calender
                  <img src={connectCalendar} alt={connectCalendar} />
                </>
              }
            />
          </button>
        ) : (
          <>
            <div></div>
          </>
        )}
      </div> */}
      {/* Sorting Options */}
      <div className="sorting-controls">
        <Form.Label
          style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#475569",
            whiteSpace: "nowrap",
            margin: "0",
          }}
        >
          Sort By:{" "}
        </Form.Label>
        <Form.Select
          defaultValue={sortBy || ""}
          onChange={(e) => {
            setSortBy(e.target.value);
            setSortOrder("");
          }}
          className="form-select"
          aria-label="Select Framework"
        >
          <option value={""}>Select</option>
          <option value="title">Title</option>
          <option value="date">Date</option>
        </Form.Select>
        <Form.Label
          style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#475569",
            whiteSpace: "nowrap",
            margin: "0",
          }}
        >
          Order:{" "}
        </Form.Label>
        <Form.Select
          defaultValue={sortOrder || ""}
          onChange={(e) => setSortOrder(e.target.value)}
          className="form-select"
          aria-label="Select Framework"
          disabled={!sortBy}
        >
          <option value={""}>Select</option>
          <option value="ascending">Ascending</option>
          <option value="descending">Descending</option>
        </Form.Select>
      </div>
      <Tabs
        id="controlled-tab-example"
        defaultActiveKey="meetings"
        // activeKey={key}
        // onSelect={(k) => setKey(k)}
        onSelect={handleTabSelect}
        className="mb-3 tab--timeline"
      >
        <Tab eventKey="meetings" title="Meetings" className="tab--timeline">
          <Accordion defaultActiveKey="1">
            <div className="rightbox">
              <div className="rb-container">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <StrictModeDroppable droppableId="droppable">
                    {(provided) => (
                      <ul
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="rb custom-scroll"
                        // style={{
                        //   height: "100%",
                        //   maxHeight: "calc(100vh - 560px)",
                        // }}
                      >
                        {currentMeetings?.map((item, i) => (
                          <Draggable
                            key={i.toString()}
                            draggableId={i.toString()}
                            index={i}
                          >
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  userSelect: "none",
                                  // backgroundColor: snapshot.isDragging
                                  //   ? "#263B4A"
                                  //   : "#456C86",
                                  ...provided.draggableProps.style,
                                }}
                                className="rb-item"
                                // ng-repeat="itembx"
                              >
                                <span
                                  style={{
                                    position: "absolute",
                                    width: "1px",
                                    top: "44px",
                                    left: "11px",
                                    bottom: "-6px",
                                    borderLeft: handleUpcoming(item)
                                      ? "2px dashed blue"
                                      : "2px dashed green",
                                  }}
                                />
                                {handleUpcoming(item) ? (
                                  <span
                                    style={{
                                      position: "absolute",
                                      left: -24,
                                    }}
                                  >
                                    {pendingTimelineIcon}
                                  </span>
                                ) : (
                                  <img src={starImg} alt="" />
                                )}
                                <div className="timelineBox">
                                  <Accordion.Item>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      // eventKey={i.toString()}
                                      onClick={() => handleToggle(i)}
                                      // aria-controls={`collapse-${i}`}
                                    >
                                      <div className="d-flex align-items-center  justify-content-between flex-grow-1 h-100">
                                        <div className="d-flex gap-3">
                                          {/* <div className="d-flex align-items-md-center align-items-sm-start gap-3 flex-md-row flex-column align-self-sm-start h-100 flex-grow-1"> */}
                                          <div {...provided.dragHandleProps}>
                                            <RxDragHandleDots2
                                              // style={{
                                              //   cursor: "grab",
                                              // }}
                                              color="#94A3B8"
                                              width={30}
                                              height={30}
                                            />
                                          </div>
                                          <span
                                            className="timeline--title"
                                            title={
                                              item.meetingName ||
                                              item.summaryTitle ||
                                              item.title
                                            }
                                          >
                                            {(item?.meetingName &&
                                              removeQuotationMarks(
                                                item.meetingName
                                              )) ||
                                              (item?.summaryTitle &&
                                                removeQuotationMarks(
                                                  item.summaryTitle
                                                )) ||
                                              (item?.title &&
                                                removeQuotationMarks(
                                                  item.title
                                                ))}
                                          </span>
                                        </div>
                                        <div className="d-flex align-items-center timelineList gap-3 meeting-header--actions">
                                          {item?.meetingLabel && (
                                            <span className="meetingLabelPill">
                                              {item.meetingLabel}
                                            </span>
                                          )}
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          >
                                            {detailsModal.id === item.id ? (
                                              <span className="d-flex align-items-center gap-2">
                                                <ReactDatePicker
                                                  className="form-control w-100 "
                                                  selected={
                                                    detailsModal.meetingStartDateTime
                                                      ? new Date(
                                                          detailsModal.meetingStartDateTime
                                                        )
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    setDetailsModal({
                                                      ...detailsModal,
                                                      meetingStartDateTime: e,
                                                    });
                                                  }}
                                                  showTimeSelect
                                                  dateFormat="Pp"
                                                  timeFormat="hh:mm a"
                                                />
                                                <button
                                                  disabled={updating}
                                                  onClick={updateMeetingDate}
                                                  type="button "
                                                  className=" updateDealBtn "
                                                >
                                                  {updating
                                                    ? "Updating..."
                                                    : "Update"}
                                                </button>
                                              </span>
                                            ) : (
                                              <span
                                                onClick={(e) => {
                                                  // if (item?.meetingStartLink)
                                                  e.stopPropagation();
                                                  setDetailsModal({
                                                    ...item,
                                                    type: "editDate",
                                                  });
                                                }}
                                                style={{
                                                  cursor: item?.meetingStartLink
                                                    ? "pointer"
                                                    : //to be removed if only meeting's date needs to be updated
                                                      "pointer",
                                                }}
                                                className="badge badge-gray time-badge"
                                              >
                                                {formatDate(
                                                  item.meetingStartDateTime
                                                )}
                                              </span>
                                            )}
                                          </span>
                                          <label
                                            className="switch summary-toggle"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <p>
                                              {showExtSummaryForID === item.id
                                                ? "Chapter Description"
                                                : "Summary"}
                                            </p>
                                            <input
                                              type="checkbox"
                                              id={`select_meeting_${i}`}
                                              name={`select_meeting_${i}`}
                                              key={showExtSummaryForID}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setShowExtSummaryForID(
                                                  showExtSummaryForID ===
                                                    item.id
                                                    ? -1
                                                    : item.id
                                                );
                                              }}
                                              onChange={() => {}}
                                              checked={
                                                showExtSummaryForID === item.id
                                              }
                                            />
                                            <span className="slider"></span>
                                          </label>
                                          <span className="text-capitalize badge badge-outline badge-outline-green">
                                            {item.status}
                                          </span>
                                          <Dropdown
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          >
                                            <Dropdown.Toggle
                                              variant="transparent"
                                              id="dropdown-basic"
                                            >
                                              <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              {/* {item?.meetingStartLink && ( */}
                                              <Dropdown.Item>
                                                <button
                                                  className="editDeletebtn"
                                                  onClick={() =>
                                                    item?.meetingStartLink
                                                      ? setDetailsModal(item)
                                                      : setEditSummary(item.id)
                                                  }
                                                >
                                                  <i className="fa-solid fa-pen" />
                                                  {item?.meetingStartLink
                                                    ? "View details"
                                                    : "Edit Summary"}
                                                </button>
                                              </Dropdown.Item>
                                              {/* )} */}
                                              <Dropdown.Item>
                                                <button
                                                  className="editDeletebtn"
                                                  onClick={() =>
                                                    setModalDeleteShow(item.id)
                                                  }
                                                >
                                                  <i className="fa-solid fa-trash-can"></i>{" "}
                                                  Delete
                                                </button>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <i
                                            style={{
                                              rotate: openCollapses.includes(i)
                                                ? "180deg"
                                                : "",
                                              transition:
                                                "all ease-in-out 300ms",
                                            }}
                                            className="fa-solid fa-angle-down"
                                          ></i>
                                        </div>
                                      </div>
                                    </div>
                                    <Accordion.Collapse
                                      in={openCollapses.includes(i)}
                                      // eventKey={i.toString()}
                                      // className={openCollapses.includes(i) ? "show" : ""}
                                    >
                                      <>
                                        <div className="list-wrapper">
                                          {/* <p className="meeting-list-title mt-1">
                                Alex Kicks Off Reviewing the Previous Year’s
                                Performance
                              </p> */}
                                          {editSummary === item.id ? (
                                            <>
                                              <div className="form-floating">
                                                <textarea
                                                  style={{
                                                    minHeight: "200px",
                                                  }}
                                                  defaultValue={
                                                    item.summary?.Summary ||
                                                    item.summary
                                                  }
                                                  className="form-control"
                                                  ref={summaryRef}
                                                  placeholder="Enter Summary"
                                                  id="floatingTextarea"
                                                ></textarea>
                                                <label for="floatingTextarea">
                                                  Summary
                                                </label>
                                              </div>
                                              <div className="profileBtn mt-3 w-100 d-flex justify-content-end ">
                                                <button
                                                  onClick={() =>
                                                    setEditSummary(null)
                                                  }
                                                  className="cancel-button"
                                                >
                                                  cancel
                                                </button>
                                                <button
                                                  onClick={saveData}
                                                  className="save-button ms-2 disabled:opacity-50 "
                                                >
                                                  {updating ? (
                                                    <Spinner />
                                                  ) : (
                                                    "Save"
                                                  )}
                                                </button>
                                              </div>
                                            </>
                                          ) : showExtSummaryForID ===
                                            item.id ? (
                                            <>
                                              {handleExtensiveSummary(
                                                item?.extensiveSummary
                                              )}
                                            </>
                                          ) : (
                                            <p className="meeting-list">
                                              {item.summary?.Summary ||
                                                item.summary}
                                            </p>
                                          )}

                                          {/* (
                                              <p className="meeting-list">
                                                {showExtSummaryForID === item.id
                                                 ? item?.extensiveSummary || ""
                                                  : item.summary?.Summary ||
                                                   item.summary}
                                            </p>
                                           ) */}
                                        </div>
                                        <div className="d-flex flex-wrap align-items-start ">
                                          <div className="people-boxes d-flex flex-wrap">
                                            {Array.isArray(item.people) &&
                                              item.people?.map(
                                                (user, index) => (
                                                  <ProfileImage
                                                    key={index}
                                                    {...user}
                                                  />
                                                )
                                              )}
                                          </div>
                                        </div>
                                      </>
                                    </Accordion.Collapse>
                                  </Accordion.Item>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <li className="rb-item" ng-repeat="itembx">
                          <span
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 10,
                            }}
                          >
                            {notSheduledIcon}
                          </span>
                          <div
                            className={`timelineBox ${
                              !data?.deal?.length && "disabled"
                            }`}
                          >
                            <div className="d-flex gap-3 flex-md-row flex-column">
                              <Card
                                // onClick={() => setMeetingModal("uploadTranscript")}
                                className="position-relative d-flex align-items-center justify-content-center dotted-border"
                              >
                                <label
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  className="position-absolute d-flex justify-content-center align-items-center  h-100 w-100"
                                >
                                  <div className=" d-flex align-items-center gap-3">
                                    <span className="social-con--title">
                                      {creating ? (
                                        <span className="d-flex align-items-center gap-3">
                                          Uploading <Spinner />
                                        </span>
                                      ) : (
                                        "Upload Transcript"
                                      )}
                                    </span>
                                    {!creating && (
                                      <span>
                                        <img src={addOnImg} alt="" />
                                      </span>
                                    )}
                                    {data?.deal?.length ? (
                                      <input
                                        disabled={creating}
                                        type="file"
                                        className="d-none"
                                        onChange={handleFileUpload}
                                        multiple
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </label>
                              </Card>
                            </div>
                          </div>
                        </li>
                        {/* Pagination buttons */}
                        {meetings?.length && meetings.length > 10 && (
                          <div className="pagination">
                            <button
                              onClick={goToPreviousPage}
                              disabled={currentPage === 1}
                            >
                              Prev
                            </button>
                            {totalPages > 5 && currentPage >= 3 && (
                              <button onClick={goToFirstPage}>
                                <FaAnglesLeft />
                              </button>
                            )}
                            {pages.map((page) => (
                              <button
                                key={page}
                                onClick={() => setPage(page)}
                                className={currentPage === page ? "active" : ""}
                              >
                                {page}
                              </button>
                            ))}
                            {totalPages > 5 &&
                              currentPage <= totalPages - 3 && (
                                <button onClick={goToLastPage}>
                                  <FaAnglesRight />
                                </button>
                              )}
                            <button
                              onClick={goToNextPage}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </ul>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              </div>
            </div>
          </Accordion>
        </Tab>
        <Tab
          eventKey="attachments"
          title="Attachments"
          className="tab--timeline"
        >
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex justify-content-center gap-4">
              <ButtonGroup style={{ borderRadius: "12px", overflow: "hidden" }}>
                <Button
                  onClick={() => setMeetingModal("uploadTranscript")}
                  className="whiteBtn"
                >
                  Add a New File
                </Button>
                <DropdownButton
                  as={ButtonGroup}
                  title=""
                  id="bg-nested-dropdown"
                  className="grayDrop-down"
                >
                  <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
              <ButtonGroup aria-label="Basic example">
                <Button variant="secondary">
                  <BsList />
                </Button>
                <Button variant="secondary">
                  <BiSolidGridAlt />
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="attachmenttable">
            <table className="w-100 attachment-table">
              <thead>
                <tr>
                  <th style={{ width: "30%" }}>File Name</th>
                  <th style={{ width: "30%" }}>File Type</th>
                  <th style={{ width: "30%" }}>Upload Date</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {attachments && attachments.length > 0 ? (
                  attachments.map((attachment) => (
                    <tr key={attachment.id}>
                      <td className="d-flex align-items-center gap-2 file-type--col">
                        {attachment.title?.split(".").pop() === "pdf" && (
                          <img src={pdfFolderIcon} alt="PDF" />
                        )}
                        {attachment.title?.split(".").pop() === "excel" && (
                          <img src={excelFolderIcon} alt="Excel" />
                        )}
                        {attachment.title?.split(".").pop() === "doc" ||
                          (attachment.title?.split(".").pop() === "docx" && (
                            <img src={docFolderIcon} alt="Doc" />
                          ))}
                        <span>{attachment.title}</span>
                      </td>
                      <td className="file-type--col">
                        {attachment.title?.split(".").pop()}
                      </td>
                      <td>{formatDate(attachment.meetingStartDateTime)}</td>
                      <td>
                        <div className="action-icons">
                          <img
                            alt="Share"
                            onClick={() => handleShare(attachment.title)}
                            src={forwardIcon}
                          />
                          <a
                            style={{ all: "unset", cursor: "pointer" }}
                            href={attachment.attachment[0]?.fileName}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={downloadIcon} alt="Download" />
                          </a>
                          <img
                            alt="Delete"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModalDeleteShow(attachment.id);
                            }}
                            src={deleteIcon}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="align-start">
                      No attachments available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Tab>
      </Tabs>{" "}
      <MyDeletePopup
        isLoading={isLoading}
        show={modalDeleteShow !== false}
        confirm={confirm}
        onHide={() => setModalDeleteShow(false)}
      />
      <Details
        data={detailsModal}
        show={detailsModal !== false && detailsModal?.type !== "editDate"}
        onHide={() => setDetailsModal(false)}
      />
      <MeetingModalBox
        accounts={data ? [data] : []}
        user={user}
        title={meetingModal === "uploadTranscript" ? "Upload File" : ""}
        uploadTranscript={meetingModal === "uploadTranscript"}
        data={{
          meetingType: meetingModal,
          accountId: data?.id,
          dealId: deal?.id,
        }}
        show={meetingModal !== false}
        refetch={() => {}}
        onHide={() => setMeetingModal(false)}
      />
    </div>
  );
};

export default TimelineTab;
