import React, { useEffect, useState } from "react";
import { getRandomColor } from "../../utils/CommonFuntions";

const BusinesCasePDF = ({
  deal,
  user,
  templates = {},
  evData = {},
  createdAt = "N/A",
  selectedFrameWork,
  targetRef,
}) => {
  const { frameWork = [] } = templates;
  const [displayedItems, setDisplayedItems] = useState([]);
  const [processedData, setProcessedData] = useState({});

  useEffect(() => {
    if (selectedFrameWork) {
      const selectedFrameworkData = frameWork.find(
        (fw) => parseInt(fw.id) === parseInt(selectedFrameWork)
      );

      const evidenceData = { ...evData };
      if (selectedFrameworkData) {
        const items = selectedFrameworkData.headlines
          .map((it) => {
            const item = it.headline.title;
            const value = evidenceData?.userContent?.[item] || {
              data: [{ content: [], title: "" }],
              removedFromBusinessCase: false,
            };
            return { item, value };
          })
          .filter(({ value }) => !value.removedFromBusinessCase)
          .map(({ item, value }) => [item, value]);

        setDisplayedItems(items);
      } else {
        setDisplayedItems([]);
      }
    } else {
      setDisplayedItems([]);
    }
  }, [evData, selectedFrameWork, frameWork]);

  useEffect(() => {
    const data = displayedItems.reduce((acc, [item, value]) => {
      const filteredData = value?.data?.filter(
        (valueItem) =>
          valueItem?.summary &&
          valueItem?.content?.every(
            (contentItem) =>
              contentItem.evidence && contentItem.usedInBusinessCase
          )
      );
      acc[item] = filteredData || [];
      return acc;
    }, {});
    setProcessedData(data);
  }, [displayedItems]);

  // Helper function to get details about a person
  const getPersonDetails = (mode = "full", item) => {
    const name = item?.title?.split("-")[0] || "";
    const designation = item?.title?.split("-")[1] || "";
    const evidence = item?.summary || "";

    if (mode === "initals") {
      return name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("");
    } else if (mode === "full") {
      return name;
    } else if (mode === "designation") {
      return designation;
    } else if (mode === "evidence") {
      return evidence;
    }
  };

  return (
    <div
      style={{ height: "100%", maxHeight: "calc(100vh - 400px)" }}
      className="custom-scroll"
    >
      {/* PDF Page */}
      <div className="pdf-report" ref={targetRef}>
        {/* Header Section */}
        <section className="header section">
          <div className="info">
            <p>Report Title:</p>
            <p>
              <strong>{deal?.dealName || ""}</strong>
            </p>
            <p>Create Date:</p>
            <p>
              <strong>{createdAt}</strong>
            </p>
            <p>Prepared by:</p>
            <p>
              <strong>
                {`${user?.firstName || ""} ${user?.lastName || ""}`.trim() ||
                  "N/A"}
              </strong>
            </p>
          </div>
        </section>

        {/* Render Displayed Items without additional filtering */}
        {displayedItems.map(([item], itemKey) => {
          const filteredData = processedData[item];

          // return filteredData && filteredData.length > 0 ? (
          //   <section className="gap-6 section" key={itemKey}>
          //     <h3>{item}</h3>
          //     {item === "People Involved" ? (
          //       filteredData.map((valueItem, valueItemIndex) => {
          //         const rowColor = getRandomColor();
          //         return (
          //           <div className="people-involved-rows" key={valueItemIndex}>
          //             <div className="labels">
          //               <div
          //                 className="pfp"
          //                 style={{ backgroundColor: rowColor, color: "#fff" }}
          //               >
          //                 {getPersonDetails("initals", valueItem || "")}
          //               </div>
          //               <div className="name-role">
          //                 <p>{getPersonDetails("full", valueItem || "")}</p>
          //                 <p className="role">
          //                   {getPersonDetails("designation", valueItem || "")}
          //                 </p>
          //               </div>
          //             </div>
          //             <div className="content">
          //               <p>{getPersonDetails("evidence", valueItem || "")}</p>
          //             </div>
          //           </div>
          //         );
          //       })
          //     ) : item === "Tech Stack" ? (
          //       <section className="tech-stack-cards">
          //         {filteredData.map((valueItem, valueItemIndex) => (
          //           <div className="card" key={valueItemIndex}>
          //             <p className="label">{valueItem?.title || "N/A"}</p>
          //             <div className="content">
          //               <p>
          //                 {valueItem?.formattedSummary &&
          //                 Array.isArray(valueItem?.formattedSummary) &&
          //                 valueItem?.formattedSummary?.length ? (
          //                   valueItem.formattedSummary.map(
          //                     (summaryItem, summaryItemIndex) => (
          //                       <div key={summaryItemIndex} className="data">
          //                         {Object.entries(summaryItem).map(
          //                           ([key, value], index) => (
          //                             <React.Fragment key={index}>
          //                               <div className="data-row">
          //                                 <p className="data-row-key">{key}:</p>
          //                                 <p className="data-row-value">
          //                                   {value}
          //                                 </p>
          //                               </div>
          //                               {/* <div className="data-row-hr" /> */}
          //                             </React.Fragment>
          //                           )
          //                         )}
          //                       </div>
          //                     )
          //                   )
          //                 ) : (
          //                   <>No data to display</>
          //                 )}
          //               </p>
          //             </div>
          //           </div>
          //         ))}
          //       </section>
          //     ) : (
          //       <div className="d-flex flex-column gap-4">
          //         {filteredData.map((valueItem, itemIndex) => (
          //           <div className="d-flex flex-column gap-2" key={itemIndex}>
          //             <h4 className="m-0">{valueItem?.title ?? ""}</h4>
          //             <p>{valueItem?.summary || "No evidence points added"}</p>
          //           </div>
          //         ))}
          //       </div>
          //     )}
          //   </section>
          // ) : null;
          return filteredData && filteredData.length > 0 ? (
            <section className="gap-6 section" key={itemKey}>
              {item === "People Involved" ? (
                <>
                  <h3>{item}</h3>
                  {filteredData.map((valueItem, valueItemIndex) => {
                    const rowColor = getRandomColor();
                    return (
                      <div
                        className="people-involved-rows"
                        key={valueItemIndex}
                      >
                        <div className="labels">
                          <div
                            className="pfp"
                            style={{ backgroundColor: rowColor, color: "#fff" }}
                          >
                            {getPersonDetails("initals", valueItem || "")}
                          </div>
                          <div className="name-role">
                            <p>{getPersonDetails("full", valueItem || "")}</p>
                            <p className="role">
                              {getPersonDetails("designation", valueItem || "")}
                            </p>
                          </div>
                        </div>
                        <div className="content">
                          <p>{getPersonDetails("evidence", valueItem || "")}</p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : item === "Tech Stack" ? (
                <>
                  <div className="section-header-group">
                    <h3>{item}</h3>
                    <section className="tech-stack-cards">
                      {filteredData.map((valueItem, valueItemIndex) => (
                        <div className="card" key={valueItemIndex}>
                          <p className="label">{valueItem?.title || "N/A"}</p>
                          <div className="content">
                            <p>
                              {valueItem?.formattedSummary &&
                              Array.isArray(valueItem?.formattedSummary) &&
                              valueItem?.formattedSummary?.length ? (
                                valueItem.formattedSummary.map(
                                  (summaryItem, summaryItemIndex) => (
                                    <div
                                      key={summaryItemIndex}
                                      className="data"
                                    >
                                      {Object.entries(summaryItem).map(
                                        ([key, value], index) => (
                                          <React.Fragment key={index}>
                                            <div className="data-row">
                                              <p className="data-row-key">
                                                {key}:
                                              </p>
                                              <p className="data-row-value">
                                                {value}
                                              </p>
                                            </div>
                                          </React.Fragment>
                                        )
                                      )}
                                    </div>
                                  )
                                )
                              ) : (
                                <>No data to display</>
                              )}
                            </p>
                          </div>
                        </div>
                      ))}
                    </section>
                  </div>
                </>
              ) : (
                <>
                  <div className="section-header-group">
                    <h3>{item}</h3>
                    {filteredData[0] && (
                      <div className="d-flex flex-column gap-2 sub-section">
                        <h4 className="m-0">{filteredData[0].title ?? ""}</h4>
                        <p>
                          {filteredData[0].summary ||
                            "No evidence points added"}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* Render remaining sub-sections individually */}
                  <div className="d-flex flex-column gap-4">
                    {filteredData.slice(1).map((valueItem, itemIndex) => (
                      <div
                        className="d-flex flex-column gap-2 sub-section"
                        key={itemIndex}
                      >
                        <h4 className="m-0">{valueItem.title ?? ""}</h4>
                        <p>{valueItem.summary || "No evidence points added"}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </section>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default BusinesCasePDF;
