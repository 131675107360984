import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  titles: [],
  bcbActiveTab: "Evidence",
  framework: "",
};

const dataSlice = createSlice({
  name: "dataSlice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setTitles: (state, action) => {
      state.titles = action.payload;
    },
    setBCBActiveTab: (state, action) => {
      state.bcbActiveTab = action.payload;
    },
    setFramework: (state, action) => {
      state.framework = action.payload;
    },
  },
});
export const { setUser, setTitles, setBCBActiveTab, setFramework } =
  dataSlice.actions;
export default dataSlice.reducer;
