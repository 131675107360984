import React, { useEffect, useState } from "react";
import googleSlidesIcon from "../../img/pitch-deck/tool-g-slides.png";
import powerPointIcon from "../../img/pitch-deck/tool-powerpoint.png";
import gammaIcon from "../../img/pitch-deck/tool-gamma.png";
import slideBeansIcon from "../../img/pitch-deck/tool-slide-beans.png";
import { Form, Modal } from "react-bootstrap";
import { DynamicTooltipbox } from "../Resetpasswordpage/Tooltipbox";
import { useGenerateGoogleSlideMutation } from "../../redux-setup/apiSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from "react-toastify";

const PitchDeckBuilder = ({ data, selectedFrameWork }) => {
  const [showSetupScreen, setShowSetupScreen] = useState(true);
  return (
    <>
      <div className="pitch-deck-builder">
        {showSetupScreen && (
          <SetupScreen data={data} selectedFrameWork={selectedFrameWork} />
        )}
      </div>
    </>
  );
};

const SetupScreen = ({ data, selectedFrameWork }) => {
  const [generateGoogleSlide] = useGenerateGoogleSlideMutation({});
  const [showWizardModal, setShowWizardModal] = useState({
    show: false,
    tool: "",
  });
  const toolsData = [
    {
      id: 1,
      name: "google-slides",
      title: "Google Slides",
      description: "Google Slides Description",
      icon: googleSlidesIcon,
      alt: "some-alt-text",
      color: "#F5BA15",
      comingSoon: false,
    },
    {
      id: 2,
      name: "powerpoint",
      title: "PowerPoint",
      description: "PowerPoint Description",
      icon: powerPointIcon,
      alt: "some-alt-text",
      color: "#d35230",
      comingSoon: true,
    },
    {
      id: 3,
      name: "gamma",
      title: "Gamma",
      description: "Gamma Description",
      icon: gammaIcon,
      alt: "some-alt-text",
      color: "#9f0cf7",
      comingSoon: true,
    },
    {
      id: 4,
      name: "slidebeans",
      title: "Slidebeans",
      description: "Slidebeans Description",
      icon: slideBeansIcon,
      alt: "some-alt-text",
      color: "#fa4371",
      comingSoon: true,
    },
  ];
  const [googleSlidesPath, setGoogleSlidesPath] = useState(null);

  const handleShowWizardModal = (tool) => {
    if (tool.name === "google-slides" && selectedFrameWork)
      getGoogleSlideData();
    else {
      toast.error("Please select a framework first!");
      return;
    }
    setShowWizardModal({ show: true, tool: tool });
  };

  const getGoogleSlideData = () => {
    const payload = {
      userId: data?.userId || 0,
      frameworkId: parseInt(selectedFrameWork?.toString() ?? "0"),
      dealId: data?.deal[0]?.id,
      dealName: data?.deal[0]?.dealName,

      // userId: 1,
      // frameworkId: 59,
      // dealId: 78,
      // dealName: "Test Deal",
    };
    generateGoogleSlide(payload)
      .unwrap()
      .then((res) => setGoogleSlidesPath(res?.presentationId || null))
      .catch((err) => console.error(err));
  };
  return (
    <>
      <div className="setup-screen">
        <div className="header">
          <h3>Create a new Pitch Deck</h3>
          <p>Please select, how you want to create your Pitch Deck!</p>
        </div>
        <div className="tools">
          {toolsData.map((tool, toolIndex) =>
            tool.comingSoon ? (
              <DynamicTooltipbox
                key={toolIndex}
                infoText={"Coming soon"}
                label={
                  <div
                    className="card coming-soon"
                    style={{
                      borderColor: tool.color,
                    }}
                  >
                    <img src={tool.icon} alt={tool.alt} />
                    <div className="content">
                      <h5 style={{ color: tool.color }}>{tool.title}</h5>
                      <p>{tool.description}</p>
                    </div>
                  </div>
                }
              />
            ) : (
              <div
                className="card"
                key={toolIndex}
                style={{
                  borderColor: tool.color,
                }}
                onClick={() => handleShowWizardModal(tool)}
              >
                <img src={tool.icon} alt={tool.alt} />
                <div className="content">
                  <h5 style={{ color: tool.color }}>{tool.title}</h5>
                  <p>{tool.description}</p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {showWizardModal.show && (
        // <div className="coming-soon-pill">
        //   <p>Coming Soon</p>
        // </div>
        <WizardModal
          tool={showWizardModal.tool}
          show={showWizardModal}
          setShow={setShowWizardModal}
          googleSlidesPath={googleSlidesPath}
          setGoogleSlidesPath={setGoogleSlidesPath}
        />
      )}
    </>
  );
};

const WizardModal = ({
  tool,
  show,
  setShow,
  googleSlidesPath,
  setGoogleSlidesPath,
}) => {
  console.log("WizardModal ~ googleSlidesPath:", googleSlidesPath);
  return (
    <Modal
      show={show}
      onHide={() => {
        setGoogleSlidesPath(null);
        setShow(false);
      }}
      className="modal-fullscreen"
      centered
    >
      {tool.name === "google-slides" ? (
        <>
          {!googleSlidesPath ? (
            <div className="loader">
              <AiOutlineLoading3Quarters size={100} className="icon-rotating" />
              <h4>Loading...</h4>
            </div>
          ) : (
            <iframe
              // src="https://docs.google.com/presentation/d1prgDJbP_Svkqx61MI1FxJHCxomt9eHFnfaJ5ECdOL-0"
              src={`https://docs.google.com/presentation/d/${googleSlidesPath}`}
              title="description"
              style={{ height: "90vh", width: "90vw" }}
            ></iframe>
          )}
        </>
      ) : (
        <Form className="pitchDeckPopup">
          <Modal.Header closeButton>
            <Modal.Title>Create Pitch Deck with {tool.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <p>
            This wizard will guide you through the process of creating your
            Pitch Deck using {tool}.
          </p> */}
            <Form.Group className="pitchDeckFormFields">
              <Form.Label>Slides Configuration: </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter number of slides"
                name="numberOfSlides"
                onChange={(e) => {}}
              />
              <Form.Label>Slide Styles: </Form.Label>
              <Form.Group className="d-flex gap-2">
                <Form.Select
                  defaultValue={""}
                  onChange={(e) => {}}
                  className="form-select"
                  aria-label="Select Layout"
                >
                  <option value={""}>Select Layout</option>
                  <option value="title">Layout 1</option>
                  <option value="date">Layout 2</option>
                </Form.Select>
                <Form.Select
                  defaultValue={""}
                  onChange={(e) => {}}
                  className="form-select"
                  aria-label="Select Theme"
                >
                  <option value={""}>Select Theme</option>
                  <option value="title">Theme 1</option>
                  <option value="date">Theme 2</option>
                </Form.Select>
                <Form.Select
                  defaultValue={""}
                  onChange={(e) => {}}
                  className="form-select"
                  aria-label="Select Theme"
                >
                  <option value={""}>Slide Animation</option>
                  <option value="title">Animation 1</option>
                  <option value="date">Animation 2</option>
                </Form.Select>
              </Form.Group>
              <Form.Label>Text Styles: </Form.Label>
              <Form.Group className="text-styles">
                <Form.Select
                  defaultValue={""}
                  onChange={(e) => {}}
                  className="form-select"
                  aria-label="Select Theme"
                >
                  <option value={""}>Font Size</option>
                  {Array.from({ length: 72 - 12 + 1 }, (_, i) => 12 + i).map(
                    (size, idx) => (
                      <option value={size} key={idx}>{`${size} px`}</option>
                    )
                  )}
                </Form.Select>
                <Form.Select
                  defaultValue={""}
                  onChange={(e) => {}}
                  className="form-select"
                  aria-label="Select Theme"
                >
                  <option value={""}>Font Style</option>
                  <option value="title">Theme 1</option>
                  <option value="date">Theme 2</option>
                </Form.Select>
                <Form.Select
                  defaultValue={""}
                  onChange={(e) => {}}
                  className="form-select"
                  aria-label="Select Theme"
                >
                  <option value={""}>Font Family</option>
                  <option value="title">Theme 1</option>
                  <option value="date">Theme 2</option>
                </Form.Select>
              </Form.Group>
              <Form.Label>Add Images: </Form.Label>
              <div className="dotted-border rounded-pill d-flex align-items-center">
                Drop here to upload
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setShow(false)} className="btn-secondary">
              Close
            </button>
            <button onClick={() => setShow(false)} className="btn-primary">
              Create Pitch Deck
            </button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};

export default PitchDeckBuilder;
