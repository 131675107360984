import React, { useRef, useState } from "react";
import { Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import downloadDoc from "../../img/downloadDoc.svg";
import genratebtn from "../../img/generatebtnBlue.svg";
import shareImg from "../../img/shareimg.svg";
import {
  useGenerateQuickBusinessCaseMutation,
  useGetAccountPricingQuery,
  useGetBusinessCasePDFMutation,
  useGetEvidenceDataQuery,
  useGetUserTemplatesByDealIdQuery,
} from "../../redux-setup/apiSlice";
import { DynamicTooltipbox } from "../Resetpasswordpage/Tooltipbox";
import BusinessCase from "./BusinessCase";
import ChartGraphTab from "./ChartGraphTab";
import Evidence from "./Evidence";
import ProductPricingtab from "./ProductPricingtab";
import BusinesCasePDF from "./BusinesCasePDF";
import { useDispatch } from "react-redux";
import { setBCBActiveTab } from "../../redux-setup/dataSlice";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { pdfSetup } from "../../utils/pdfUtils";

const BusinessCaseBuilderTab = ({
  deal,
  selectedFrameWork = "",
  setSelectedFrameWork,
}) => {
  const user = useSelector((state) => state.dataSlice.user);
  const dispatch = useDispatch();
  const targetRef = useRef();
  const { data: templates = {} } = useGetUserTemplatesByDealIdQuery(
    {
      userID: user?.id,
      dealID: deal?.id,
    },
    { skip: !user?.id || !deal?.id }
  );
  const { data: pricingData } = useGetAccountPricingQuery(deal?.id, {
    skip: !deal?.id,
  });
  const { data: evData } = useGetEvidenceDataQuery(deal?.id, {
    skip: !deal?.id,
  });
  const [getPDFData] = useGetBusinessCasePDFMutation();
  const [activeTab, setActiveTab] = useState("Evidence"); // Track the active tab
  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
    dispatch(setBCBActiveTab(selectedTab));
    setShowBcbPDF(false);
  };
  const [setupModal, setSetupModal] = React.useState(false);
  const [showBcbPDF, setShowBcbPDF] = useState(false);
  const [pdfCreatedAt, setPDFCreatedAt] = useState(null);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const handlePDFGeneration = async () => {
    if (showBcbPDF) {
      setIsGeneratingPDF(true);
      if (!targetRef.current) return;
      let htmlContent = targetRef.current.innerHTML;
      htmlContent = pdfSetup(htmlContent);
      try {
        const response = await getPDFData(htmlContent).unwrap();
        const pdfBlob = new Blob([response], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `Business Case Report - Copitch.ai.pdf`;
        link.click();
        URL.revokeObjectURL(pdfUrl);
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        setIsGeneratingPDF(false);
      }
    } else {
      const currentDate = new Date();
      const formattedDate = `${("0" + (currentDate.getMonth() + 1)).slice(
        -2
      )}/${("0" + currentDate.getDate()).slice(
        -2
      )}/${currentDate.getFullYear()}`;
      setPDFCreatedAt(formattedDate);
      setShowBcbPDF(true);
    }
  };

  const [
    generateQuickBusinessCaseMutation,
    { isLoading: isGeneratingQuickBusinessCase },
  ] = useGenerateQuickBusinessCaseMutation({});
  const handleQuickBusinessCase = () => {
    generateQuickBusinessCaseMutation(evData.id)
      .unwrap()
      .then(() => {
        toast.success("Business Case Generated!");
      })
      .catch((err) => {
        toast.error(`${err?.message ?? "Failed to generate business case"}`);
      });
  };
  return (
    <>
      <div className="businessCaseBuilder">
        <div className="downloadShareBtn">
          {!showBcbPDF && activeTab === "Business Case" && (
            <button
              className="downloadDoc"
              // className="generateBtn generate-quick-business-case"
              onClick={() => handleQuickBusinessCase()}
              style={{
                paddingLeft: "12px",
              }}
            >
              {isGeneratingQuickBusinessCase ? (
                <div
                  className="d-flex align-items-center"
                  style={{
                    paddingLeft: "4px",
                    gap: "7px",
                  }}
                >
                  Generating & Updating Data...
                  <AiOutlineLoading3Quarters
                    className="icon-rotating"
                    size={16}
                  />
                </div>
              ) : (
                <div
                  className="d-flex align-items-center"
                  style={{
                    paddingLeft: "4px",
                    gap: "7px",
                  }}
                >
                  Generate Quick Business Case
                  <img src={genratebtn} alt={genratebtn} className="pl-1" />
                </div>
              )}
            </button>
          )}
          {showBcbPDF && (
            <button
              className="downloadDoc"
              onClick={() => setShowBcbPDF(false)}
            >
              Go Back
            </button>
          )}
          <button
            className={`${!selectedFrameWork ? "disabled" : "downloadDoc"}`}
            onClick={() => handlePDFGeneration()}
            disabled={!selectedFrameWork}
          >
            {/* <button className="downloadDoc" onClick={() => toPDF()}> */}
            {showBcbPDF
              ? isGeneratingPDF
                ? "Downloading..."
                : "Download Doc"
              : "Generate PDF"}{" "}
            <img src={downloadDoc} alt={downloadDoc} />{" "}
          </button>
          <button
            className="share"
            // onClick={() => setModalShow(true)}
          >
            <DynamicTooltipbox
              infoText={"Coming soon"}
              label={
                <>
                  Share <img src={shareImg} alt={shareImg} />
                </>
              }
            />
          </button>
        </div>
        <div className="relative">
          <div className=""></div>
          <div className="absolute"></div>
        </div>

        <Tabs
          defaultActiveKey="Evidence"
          id="uncontrolled-tab-example"
          onSelect={handleTabSelect}
        >
          <Tab eventKey="Evidence" title="Evidence">
            <Evidence
              templates={templates}
              evData={evData}
              selectedFrameWork={selectedFrameWork}
              setSelectedFrameWork={setSelectedFrameWork}
            />
          </Tab>
          <Tab eventKey="Products & Pricing" title="Products & Pricing">
            <ProductPricingtab deal={deal} data={pricingData} />
          </Tab>
          <Tab eventKey="Charts & Graphs" title="Charts & Graphs">
            <ChartGraphTab data={pricingData} />
          </Tab>
          <Tab eventKey="Business Case" title="Business Case">
            {showBcbPDF ? (
              <BusinesCasePDF
                createdAt={pdfCreatedAt}
                PDF
                deal={deal}
                targetRef={targetRef}
                user={user}
                templates={templates}
                evData={evData}
                selectedFrameWork={selectedFrameWork}
                setSelectedFrameWork={setSelectedFrameWork}
              />
            ) : (
              <BusinessCase
                templates={templates}
                evData={evData}
                selectedFrameWork={selectedFrameWork}
                setSelectedFrameWork={setSelectedFrameWork}
              />
            )}
          </Tab>
        </Tabs>
      </div>

      <SetupModal show={setupModal} onHide={() => setSetupModal(false)} />
    </>
  );
};

const SetupModal = (props) => {
  return (
    <Modal
      className="frameworkModal share"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Setup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="">
          <Row>
            {/* == */}
            <Form>
              <Col md={12}>
                <Row>
                  {/* ===== */}
                  <Col md={12} className="mb-3">
                    <Form.Label>Purpose</Form.Label>
                    <Form.Select aria-label="Default select example w-100">
                      <option>Evaluation Overview</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="d-flex gap-2 align-items-center ">
                        Context
                        {/* <img src={toolTip} alt="toolTip" /> */}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Requirement
                        {/* <img src={toolTip} alt="toolTip" /> */}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Focus Point
                        {/* <img src={toolTip} alt="toolTip" /> */}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="reset"
          onClick={() => {
            props.onHide();
          }}
          className="cancelBtn"
        >
          Close
        </button>
        <button type="submit" className="doneBtn">
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BusinessCaseBuilderTab;
