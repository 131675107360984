const pdfStyles = `
        <style>
          .pdf-report {
            /* width: 100%; */
            /* max-width: 210mm; */
            padding: 15mm;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            gap: 32px;
            color: #475569;
            margin-left: auto;
            margin-right: auto;
          }

          .pdf-report * {
            margin: 0;
            font-family: "Figtree", sans-serif;
          }

          .pdf-report h2 {
            font-size: 40px;
            font-weight: 700;
            line-height: 60px;
            color: #1e293b;
          }

          .pdf-report h3 {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            color: #1e293b;
            margin: 0;
            background: none;
            -webkit-text-fill-color: #000;
          }

          .pdf-report h4 {
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            color: #1e293b;
            margin-bottom: 8px;
          }

          .pdf-report ol {
            padding-left: 40px;
            display: flex;
            flex-direction: column;
            gap: 16px;
          }
          .pdf-report ol li::marker {
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            color: #1e293b;
          }

          .pdf-report p {
            font-size: 14px;
            line-height: 21px;
            color: #475569;
          }

          .pdf-report .gap-2 {
            gap: 8px;
          }

          .pdf-report .gap-6 {
            gap: 24px;
          }

          .pdf-report section {
            display: flex;
            flex-direction: column;
          }

          .pdf-report section.header {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: right;
          }

          .pdf-report section.header .info {
            width: 100%;
            max-width: 312px;
            display: grid;
            grid-template-columns: auto auto;
            gap: 8px;
          }

          .pdf-report .people-involved-rows {
            display: flex;
          }

          .pdf-report .people-involved-rows .labels {
            display: flex;
            gap: 12px;
            padding: 12px 16px;
            width: 100%;
            max-width: 40%;
          }

          .pdf-report .people-involved-rows .labels .pfp {
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            line-height: 150%;
          }

          .pdf-report .people-involved-rows .labels .name-role {
            width: 100%;
            height: 100%;
            max-height: 48px;
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            justify-content: center;
          }

          .pdf-report .people-involved-rows .labels .name-role p {
            font-weight: 600;
          }

          .pdf-report .people-involved-rows .labels .name-role .role {
            white-space: wrap;
            font-size: 14px;
            font-weight: 400;
          }
          .pdf-report .people-involved-rows .labels .name-role-responsive {
            width: 100%;
            max-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .pdf-report .people-involved-rows .labels .name-role-responsive {
            width: 100%;
            max-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .pdf-report .people-involved-rows .labels .name-role-responsive .role {
            padding: 6px 12px;
            font-size: 12px;
            line-height: 18px;
            height: min-content;
            border-radius: 28px;
            width: 100%;
            max-width: fit-content;
          }

          .pdf-report .people-involved-rows .labels .department {
            padding: 6px 12px;
            border-radius: 28px;
          }

          .pdf-report .people-involved-rows .content {
            width: 100%;
            max-width: 60%;
            display: flex;
          }

          .pdf-report .people-involved-rows .content p {
            align-self: center;
          }

          .pdf-report .tech-stack-cards {
            display: grid;
            gap: 16px;
          }

          @media (min-width: 0px) {
            .pdf-report .tech-stack-cards {
              grid-template-columns: 1fr;
            }
          }

          @media (min-width: 600px) {
            .pdf-report .tech-stack-cards {
              grid-template-columns: repeat(2, 1fr);
            }
          }

          @media (min-width: 900px) {
            .pdf-report .tech-stack-cards {
              grid-template-columns: repeat(2, 1fr);
            }
          }

          @media (min-width: 1200px) {
            .pdf-report .tech-stack-cards {
              grid-template-columns: repeat(2, 1fr);
            }
          }

          .pdf-report .tech-stack-cards .card {
            border: 1px solid #cbd5e1;
            border-radius: 24px;
            padding: 16px 20px 20px 20px;
            display: flex;
            flex-direction: column;
            gap: 24px;
          }
          .pdf-report .tech-stack-cards .card .label {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: #1e293b;
          }
          .pdf-report .tech-stack-cards .card .content {
            display: flex;
            flex-direction: column;
          }
          .pdf-report .tech-stack-cards .card .content .data {
            display: flex;
            flex-direction: column;
            gap: 16px;
          }
          .pdf-report .tech-stack-cards .card .content .data-row {
            display: flex;
            flex-wrap: wrap;
            row-gap: 8px;
            column-gap: 16px;
            border-bottom: 1px dashed #cbd5e1;
            padding-bottom: 16px;
          }
          .pdf-report .tech-stack-cards .card .content .data-row:last-of-type {
            border-bottom: 0px;
            padding-bottom: 0px;
          }
          .pdf-report .tech-stack-cards .card .content .data-row .data-row-key {
            color: #64748b;
          }
          .pdf-report .tech-stack-cards .card .content .data-row .data-row-value {
            font-weight: 600;
            color: #1e293b;
          }
          .sub-section {
            page-break-inside: avoid;
          }
          .section-header-group {
            page-break-inside: avoid;
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
          }

          /* Convert bootstrap classes to vanilla css */
          .gap-2 {
            gap: 8px;
          }
          .gap-4 {
            gap: 16px;
          }
          .gap-6 {
            gap: 24px;
          }
          .d-flex {
            display: flex;
          }
          .flex-column {
            flex-direction: column;
          }
          .m-0 {
            margin: 0;
          }
        </style>
      `;

const pdfScripts = `
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Figtree:ital,wght@0,300..900;1,300..900&display=swap"
          rel="stylesheet"
        />
      `;

const pdfTitle = "Business Case Report - Copitch.ai";
export const pdfSetup = (content) => {
  return `<html><head>${pdfStyles}${pdfScripts}<title>${pdfTitle}</title></head><body><div class="pdf-report">${content}</div></body></html>`;
};
