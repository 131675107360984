import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PROXY } from "../utils/contants";
import { getAuthToken } from "../utils/localstorage-config";

const base = PROXY;

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: base,
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: [
    "user",
    "account",
    "template",
    "templatesByDealID",
    "attachments",
    "meeting",
    "evidenceData",
  ],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (id) => `/user/${id}`,
      providesTags: ["user"],
    }),
    userUpdate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/user/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    createTemplate: builder.mutation({
      query: ({ id, data }) => ({
        url: `/templates/user/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["template"],
    }),
    updateTemplate: builder.mutation({
      query: ({ id, data }) => ({
        url: `templates/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["template", "templatesByDealID"],
    }),
    deleteTemplate: builder.mutation({
      query: (id) => ({
        url: `/templates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["template"],
    }),
    accountDelete: builder.mutation({
      query: (id) => ({ url: `/accounts/${id}`, method: "DELETE" }),
      invalidatesTags: ["account"],
    }),
    creatAccount: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `/accounts/user/${id}`,
          method: "POST",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ["account"],
    }),
    createAccountPricing: builder.mutation({
      query: (data) => {
        return {
          url: `/product-price`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["pricing"],
    }),
    updateAccountPricing: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/product-price/${id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["pricing"],
    }),
    getAccountPricing: builder.query({
      query: (id) => `/product-price/dealId/${id}`,
      providesTags: ["pricing"],
    }),
    getUserAccounts: builder.query({
      query: (id) => `/user/accounts/${id}`,
      providesTags: ["account"],
    }),
    getAccountDeals: builder.query({
      query: (id) => `/accounts/deals/${id}`,
      providesTags: ["deal"],
    }),
    getSingleDeal: builder.query({
      query: (id) => `/deals/${id}`,
      providesTags: ["deal"],
    }),
    getDealMeeting: builder.query({
      query: (id) => `/meetings/deals/${id}`,
      providesTags: ["meeting"],
    }),
    getUserTemplates: builder.query({
      query: (id) => `/templates/user/${id}`,
      providesTags: ["template"],
    }),
    getUserTemplatesByDealId: builder.query({
      query: ({ userID, dealID }) =>
        `/templates/user/${userID}?dealId=${dealID}`,
      providesTags: ["templatesByDealID"],
      invalidatesTags: ["deal"],
    }),
    deleteMeeting: builder.mutation({
      query: (id) => ({ url: `/meetings/${id}`, method: "DELETE" }),
      invalidatesTags: ["meeting"],
    }),
    deleteDeal: builder.mutation({
      query: (id) => ({ url: `/deals/${id}`, method: "DELETE" }),
      invalidatesTags: ["deal"],
    }),
    createDeal: builder.mutation({
      query: ({ id, data }) => ({
        url: `/deals/accounts/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["deal"],
    }),
    updateAccount: builder.mutation({
      query: ({ id, data, formData }) => ({
        url: `/accounts/${id}`,
        method: "PATCH",
        body: data,
        formData: formData,
      }),
      invalidatesTags: ["deal"],
    }),
    updateDeal: builder.mutation({
      query: ({ id, data }) => ({
        url: `/deals/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["deal"],
    }),
    updateMeeting: builder.mutation({
      query: ({ id, data }) => ({
        url: `/meetings/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["meeting"],
    }),
    createMeetingByDealID: builder.mutation({
      query: (data) => ({
        url: `/meetings`,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: ["meeting", "deal"],
    }),
    zoomAuth: builder.mutation({
      query: (data) => ({
        url: `/auth/zoom/callback/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    connectZoom: builder.query({
      query: () => `auth/zoom-auth-url`,
    }),
    googleVerify: builder.mutation({
      query: (data) => ({
        url: `/auth/google/verify-code`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    googleRedirect: builder.query({
      query: () => `/auth/google/oauth`,
    }),
    createPerson: builder.mutation({
      query: (data) => ({
        url: `/deals/people/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["deal"],
    }),
    getFrameworkTitles: builder.query({
      query: () => "/headlines",
      providesTags: ["frameworkTitles"],
    }),
    createFrameworkTitle: builder.mutation({
      query: (formData) => {
        return {
          url: "/templates/titles",
          method: "POST",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ["frameworkTitles"],
    }),
    updateFrameworkTitle: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `/templates/titles/${id}`,
          method: "PATCH",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ["frameworkTitles", "template"],
    }),
    deleteFrameworkTitle: builder.mutation({
      query: (id) => {
        return {
          url: `/templates/titles/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["frameworkTitles"],
    }),
    getEvidenceData: builder.query({
      query: (dealId) => `/evidence-builder/content?dealId=${dealId}`,
      providesTags: ["evidenceData"],
    }),
    updateEvidenceScore: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/evidence-builder/update/${id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["evidenceData"],
    }),
    generateEvidenceSummary: builder.mutation({
      query: (data) => {
        return {
          url: "/evidence-builder/generate-summary",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["evidenceData"],
    }),
    generateQuickBusinessCase: builder.mutation({
      query: (id) => {
        return {
          url: "/evidence-builder/generate-quick-business-case",
          method: "POST",
          body: { id },
        };
      },
      invalidatesTags: ["evidenceData"],
    }),
    getBusinessCasePDF: builder.mutation({
      query: (htmlContent) => ({
        url: "evidence-builder/pdf",
        method: "POST",
        headers: {
          "Content-Type": "text/html",
        },
        body: htmlContent,
        responseHandler: (response) => response.blob(),
      }),
    }),
    generateGoogleSlide: builder.mutation({
      query: (payload) => {
        return {
          url: "evidence-builder/generate-google-sheet",
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});
export const {
  useLazyGetUserQuery,
  useGetUserQuery,
  useUserUpdateMutation,
  //Accounts Endpoints
  useAccountDeleteMutation,
  useCreatAccountMutation,
  useUpdateAccountMutation,
  useGetUserAccountsQuery,
  // Pricing Endpoints
  useGetAccountPricingQuery,
  useUpdateAccountPricingMutation,
  useCreateAccountPricingMutation,
  //Deals endpointd
  useGetAccountDealsQuery,
  useGetSingleDealQuery,
  useCreateDealMutation,
  useDeleteDealMutation,
  useUpdateDealMutation,

  // Templates
  useGetUserTemplatesQuery,
  useGetUserTemplatesByDealIdQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,

  // Framework titles
  useGetFrameworkTitlesQuery,
  useCreateFrameworkTitleMutation,
  useUpdateFrameworkTitleMutation,
  useDeleteFrameworkTitleMutation,

  //Meetings
  useUpdateMeetingMutation,
  useDeleteMeetingMutation,
  useGetDealMeetingQuery,
  useCreateMeetingByDealIDMutation,

  // zoom auth
  useZoomAuthMutation,
  useLazyConnectZoomQuery,

  // Google auth
  useGoogleVerifyMutation,
  useLazyGoogleRedirectQuery,

  // People
  useCreatePersonMutation,

  // Evidence data by user id and deal id
  useGetEvidenceDataQuery,
  useLazyGetEvidenceDataQuery,

  // Evidence scores
  useUpdateEvidenceScoreMutation,
  useGenerateEvidenceSummaryMutation,
  useGenerateQuickBusinessCaseMutation,
  useGetBusinessCasePDFMutation,
  useGenerateGoogleSlideMutation,
} = apiSlice;
